import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import MembersComponent from "layout/marketing/email/Members";
import { debounce } from "lodash";
import { ChangeEventHandler, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearDeleteEmailSubscriber } from "redux/slices/marketing/email/deleteEmailSubscriber";
import deleteEmailSubscriber from "redux/thunks/marketing/emails/deleteEmailSubscriber";
import getEmailSubscribers from "redux/thunks/marketing/emails/getEmailSubscribers";
import { EmailSubscriber } from "types/model/NewsletterSubscriber";

function EmailMembers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dateFilterDone, setDateFilterDone] = useState(false);
  const {
    page,
    start_date = null,
    end_date = null,
    search = "",
    verified = "",
    program_location = "",
    is_home_page = "",
    client = "",
    is_distinct = "1",
    report_view = "0",
    program_view = "0",
  } = Object.fromEntries([...searchParams]);

  const getEmailSubscribersData = useAppSelector(
    (state) => state.getEmailSubscribers
  );
  const deleteEmailSubscriberData = useAppSelector(
    (state) => state.deleteEmailSubscriber
  );
  const getClientsData = useAppSelector((state) => state.getClients);

  const onFiltersChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value = "" },
  }) => {
    if (name === "dates") {
      setDateFilterDone(false);
      const url = new URL(window.location.href);
      const start = JSON.parse(value)[0];
      url.searchParams.set(
        "start_date",
        start ? dayjs(start).format("YYYY-MM-DD") : ""
      );
      const end = JSON.parse(value)[1];
      url.searchParams.set(
        "end_date",
        end ? dayjs(end).format("YYYY-MM-DD") : ""
      );

      url.searchParams.set("page", "1");

      navigate(url.search);

      return;
    } else {
      const url = new URL(window.location.href);

      url.searchParams.set(name, value);
      url.searchParams.set("page", "1");

      navigate(url.search);
    }
  };

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };
  const handleDeleteSubscriber = (item: EmailSubscriber) => {
    dispatch(deleteEmailSubscriber(item)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Subscriber deleted successfully");
        dispatch(clearDeleteEmailSubscriber());
      }
    });
  };

  const getData = () => {
    dispatch(
      getEmailSubscribers({
        page: Number(page) || 1,
        start_date: start_date || "",
        end_date: end_date || "",
        search,
        client,
        verified: verified ? (verified === "1" ? "1" : "0") : "",
        is_home_page: is_home_page === "1" ? "1" : "",
        program_location,
        is_distinct: is_distinct == "1" ? "1" : "",
      })
    );
  };
  useEffect(() => {
    getData();
  }, [
    page,
    search,
    verified,
    program_location,
    is_home_page,
    is_distinct,
    report_view,
    client,
  ]);

  const debouncedOnFiltersChange = debounce(onFiltersChange, 2000);

  const onDateSelectConfirm = () => {
    getData();
    setDateFilterDone(true);
  };

  return (
    <MembersComponent
      handleDeleteSubscriber={handleDeleteSubscriber}
      onPageChange={getNewPage}
      currentPage={Number(page) || 1}
      getEmailSubscribersData={getEmailSubscribersData}
      deleteEmailSubscriberData={deleteEmailSubscriberData}
      filterValues={{
        start_date: start_date || null,
        end_date: end_date || null,
        verified,
        search: search || null,
        is_home_page,
        program_location,
        is_distinct,
        report_view,
        dateFilterDone,
        program_view,
      }}
      onFiltersChange={onFiltersChange}
      debouncedOnFiltersChange={debouncedOnFiltersChange}
      onDateSelectConfirm={onDateSelectConfirm}
      getClientsData={getClientsData}
    />
  );
}

export default EmailMembers;
