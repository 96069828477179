import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import SeasonalLinksLayout from "layout/oma/campaigns/SeasonalLinksLayout";
import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import getSeasonalLinks from "redux/thunks/app/placements/campaigns/getSeasonalLinks";
import { SeasonalLink } from "types/model/SeasonalLink";
import updateSeasonalLinks from "redux/thunks/app/placements/campaigns/updateSeasonalLinks";
import { useSearchParams } from "react-router-dom";
import createSeasonalLink from "redux/thunks/app/placements/campaigns/createSeasonalLink";
import toast from "react-hot-toast";
import deleteSeasonalLink from "redux/thunks/app/placements/campaigns/deleteSeasonalLink";

const SeasonalLinks = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const {
    getSeasonalLinks: getSeasonalLinksData,
    updateSeasonalLinks: updateSeasonalLinksData,
    deleteSeasonalLink: { loading: deleteLoading },
  } = useAppSelector(
    ({ getSeasonalLinks, updateSeasonalLinks, deleteSeasonalLink }) => ({
      getSeasonalLinks,
      updateSeasonalLinks,
      deleteSeasonalLink,
    })
  );
  const [sortedItems, setSortedItems] = useState<SeasonalLink[]>(
    getSeasonalLinksData.data
  );

  useEffect(() => {
    dispatch(getSeasonalLinks());
  }, [params]);

  const updateSeasonalLink = (result: SeasonalLink) => {
    dispatch(
      updateSeasonalLinks({
        ...result,
        category: result.category.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(getSeasonalLinks());
        toast.success("Changes saved");
      }
    });
  };

  const moveDraggableRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSortedItems((order) =>
        update(order, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, order[dragIndex]],
          ],
        })
      );
    },
    []
  );

  useEffect(() => {
    setSortedItems(getSeasonalLinksData.data);
  }, [getSeasonalLinksData.data]);

  const saveSortOrder = () => {
    dispatch(
      updateSeasonalLinks({
        id: sortedItems[0].id,
        data: sortedItems.map((el, idx) => {
          return {
            id: el.id,
            position: idx + 1,
          };
        }),
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  const addNewCategory = (item: Record<string, any>) => {
    dispatch(
      createSeasonalLink({
        data: [
          ...sortedItems,
          {
            category: item.id,
            position: sortedItems.length + 1,
          },
        ],
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Seasonal Link created successfully!");
        dispatch(getSeasonalLinks());
      } else {
        toast.error(JSON.stringify(res.payload));
      }
    });
  };

  const handleDeleteSeasonalLink = (item: Record<string, any>) => {
    dispatch(deleteSeasonalLink(item)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Seasonal Link deleted successfully!");
        dispatch(getSeasonalLinks());
      } else {
        toast.error(JSON.stringify(res.payload));
      }
    });
  };

  return (
    <SeasonalLinksLayout
      loading={getSeasonalLinksData.loading}
      updateSeasonalLinksData={updateSeasonalLinksData}
      data={sortedItems}
      deleteLoading={deleteLoading}
      addNewCategory={addNewCategory}
      saveSortOrder={saveSortOrder}
      moveDraggableRow={moveDraggableRow}
      setSortedItems={setSortedItems}
      updateSeasonalLink={updateSeasonalLink}
      deleteSeasonalLink={handleDeleteSeasonalLink}
    />
  );
};

export default SeasonalLinks;
