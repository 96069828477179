import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  query: string;
  client_id?: string;
  isCRM?: string;
  location?: string;
};

export default createAsyncThunk(
  "clients/campaign-program-search",
  async ({ query, client_id, ...rest }: Params, ThunkAPI) => {
    try {
      const selectedClientId =
        client_id || localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }

      const clientWebsite = new ClientAPI(client_id);

      const response = await clientWebsite.searchPrograms(
        query,
        client_id,
        rest
      );

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
