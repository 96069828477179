import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import deleteSeasonalLink from "redux/thunks/app/placements/campaigns/deleteSeasonalLink";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const deleteSeasonalLinkSlice = createSlice({
  name: "deleteSeasonalLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteSeasonalLink.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        deleteSeasonalLink.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      )
      .addCase(
        deleteSeasonalLink.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default deleteSeasonalLinkSlice.reducer;
