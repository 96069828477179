import Spinner from "components/common/layout/Spinner";
import Layout from "components/layout/Layout";
import { websitesNameIdMap } from "constants/clientWebsitesIds";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import HomeCoupons from "containers/oma/campaigns/HomeCouponListings";
import TopCategories from "containers/oma/campaigns/TopCategories";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { ProgramCategory } from "types/model/Category";

type Props = {
  loading: boolean;
  data: Array<ProgramCategory>;
  navigateToLevel: (l: string) => void;
  navigateToCreate: () => void;
  level: string;
  navigateToClientCategory: (id: string) => void;
  navigateToDetail: (id: string) => void;
  navigateToEdit: (id: string) => void;
};

export default function OMACategories({
  navigateToLevel,
  navigateToCreate,
  data,
  navigateToClientCategory,
  navigateToDetail,
  level,
  loading,
  navigateToEdit,
}: Props) {
  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="r">
          <div className="flex items-center justify-between sm:flex-auto">
            <div>
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Categories
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Add Parent, Sub or Sub-II categories.
              </p>
            </div>

            <div className="">
              <button
                type="button"
                onClick={navigateToCreate}
                className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 my-8"
              >
                + Category
              </button>
            </div>
          </div>
          <div>
            <nav className="isolate flex items-center divide-x divide-gray-200 rounded-lg shadow w-2/3 mt-6">
              <button
                onClick={() => {
                  navigateToLevel("0");
                }}
                className={twMerge(
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 ",
                  data[0]?.level === 0 &&
                    !data[0]?.is_seasonal &&
                    !data[0]?.is_homepage &&
                    "text-primary-700  rounded-md"
                )}
              >
                <span>Parent</span>
                <span aria-hidden="true" className="bg-indigo-500"></span>
              </button>
              <button
                onClick={() => {
                  navigateToLevel("1");
                }}
                className={twMerge(
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
                  data[0]?.level === 1 && "text-primary-700  rounded-md"
                )}
              >
                <span>Sub</span>
                <span aria-hidden="true" className="bg-indigo-500"></span>
              </button>
              <button
                onClick={() => {
                  navigateToLevel("2");
                }}
                className={twMerge(
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
                  data[0]?.level === 2 && "text-primary-700  rounded-md"
                )}
              >
                <span>Sub II</span>
                <span aria-hidden="true" className="bg-indigo-500"></span>
              </button>

              {localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID) !==
                websitesNameIdMap["discount-codes.com"] && (
                <button
                  onClick={() => {
                    navigateToLevel("3");
                  }}
                  className={twMerge(
                    "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
                    data[0]?.is_seasonal && "text-primary-700  rounded-md"
                  )}
                >
                  <span>Seasonal</span>
                  <span aria-hidden="true" className="bg-indigo-500"></span>
                </button>
              )}

              <button
                onClick={() => {
                  navigateToLevel("home-coupons");
                }}
                className={twMerge(
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
                  data[0]?.is_homepage && "text-primary-700  rounded-md"
                )}
              >
                <span>Homepage</span>
                <span aria-hidden="true" className="bg-indigo-500"></span>
              </button>

              <button
                onClick={() => {
                  navigateToLevel("6");
                }}
                className={twMerge(
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
                  level === "6" && "text-primary-700  rounded-md"
                )}
              >
                <span>Navigation</span>
                <span aria-hidden="true" className="bg-indigo-500"></span>
              </button>
            </nav>
          </div>
        </div>

        {level === "6" && <TopCategories globalLayout={false} />}
        {loading && (
          <div className="mt-8 flow-root">
            <Spinner />
          </div>
        )}
        {!loading && (
          <div className="mt-8 flow-root">
            {level === "home-coupons" ? (
              <HomeCoupons />
            ) : (
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                {level === "6" ? null : (
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Category Name
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Category ID
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Slug
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              # of Coupons
                            </th>

                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            ></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {data
                            .sort((a, b) => (b.name > a.name ? -1 : 1))
                            .map((el) => (
                              <tr key={el.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {el.name}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {el.id}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <button
                                    onClick={() =>
                                      navigateToClientCategory(String(el.id))
                                    }
                                  >
                                    /{el.slug}
                                  </button>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {el.number_of_coupons}
                                </td>

                                <td className="relative whitespace-nowrap py-4 pl-3 text-right text-sm font-medium">
                                  <div className="flex gap-4">
                                    <button
                                      className="text-indigo-600 hover:text-indigo-900"
                                      onClick={() =>
                                        navigateToEdit(String(el.id))
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="text-indigo-600 hover:text-indigo-900"
                                      onClick={() =>
                                        navigateToDetail(String(el.id))
                                      }
                                    >
                                      Coupons
                                    </button>

                                    <Link
                                      state={{
                                        content: {
                                          ...el.content,
                                          category: el,
                                        },
                                      }}
                                      to={`/oma/categories/${el.id}/content`}
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      Content
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}
