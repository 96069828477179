import getTopCategories from "redux/slices/clients/placements/campaigns/getTopCategories";
import getTrendingStores from "redux/slices/clients/placements/campaigns/getTrendingStores";
import getCategoryStoreSpotlights from "redux/slices/clients/placements/campaigns/getCategoryStoreSpotlights";
import getCouponSpotlights from "redux/slices/clients/placements/campaigns/getCouponSpotlights";
import saveTrendingStores from "redux/slices/clients/placements/campaigns/saveTrendingStores";
import saveTopCategories from "redux/slices/clients/placements/campaigns/saveTopCategories";
import saveCategoryStoreSpotlights from "redux/slices/clients/placements/campaigns/saveCategoryStoreSpotlights";
import saveCouponSpotlights from "redux/slices/clients/placements/campaigns/saveCouponSpotlights";
import addToPaidPlacements from "redux/slices/clients/placements/campaigns/addToPaidPlacements";
import getHomeCoupons from "redux/slices/clients/placements/campaigns/getHomeCoupons";
import deletePaidPlacement from "redux/slices/clients/placements/campaigns/deletePaidPlacement";
import saveHomeCoupons from "redux/slices/clients/placements/campaigns/saveHomeCoupons";
import searchPrograms from "redux/slices/clients/placements/campaigns/searchPrograms";
import getPaidPlacements from "redux/slices/clients/placements/campaigns/getPaidPlacements";
import getSeasonalLinks from "redux/slices/clients/placements/campaigns/getSeasonalLinks";
import getPlacementTypes from "redux/slices/clients/placements/campaigns/getPlacementTypes";
import getPlacementDestinations from "redux/slices/clients/placements/campaigns/getPlacementDestinations";
import updatePaidPlacement from "redux/slices/clients/placements/campaigns/updatePaidPlacement";
import checkPlacementPosAvailability from "redux/slices/clients/placements/campaigns/checkPlacementPosAvailability";
import updateSeasonalLinks from "redux/slices/clients/placements/campaigns/updateSeasonalLinks";
import createSeasonalLink from "redux/slices/clients/placements/campaigns/createSeasonalLink";
import deleteSeasonalLink from "redux/slices/clients/placements/campaigns/deleteSeasonalLink";

const campaignReducers = {
  getTopCategories,
  saveTrendingStores,
  saveCategoryStoreSpotlights,
  getTrendingStores,
  getCategoryStoreSpotlights,
  getCouponSpotlights,
  saveTopCategories,
  deletePaidPlacement,
  saveCouponSpotlights,
  addToPaidPlacements,
  getHomeCoupons,
  saveHomeCoupons,
  searchPrograms,
  getPaidPlacements,
  updatePaidPlacement,
  checkPlacementPosAvailability,
  getSeasonalLinks,
  updateSeasonalLinks,
  getPlacementTypes,
  getPlacementDestinations,
  createSeasonalLink,
  deleteSeasonalLink,
};

export default campaignReducers;
