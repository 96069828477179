import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { ChangeEventHandler, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getCouponContributors from "redux/thunks/app/api-clients/performance/getCouponContributors";
import CouponContributions from "layout/contributions/CouponContributions";

const CouponContributors = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { client_id } = Object.fromEntries(searchParams);

  const { getCouponContributors: getCouponContributorsData } = useAppSelector(
    ({ getCouponContributors, getClients }) => ({
      getCouponContributors,
      getClients,
    })
  );

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    const url = new URL(window.location.href);
    if (name === "dates") {
      url.searchParams.set(
        "start_date",
        dayjs(JSON.parse(value)[0]).format("YYYY-MM-DD")
      );
      url.searchParams.set(
        "end_date",
        dayjs(JSON.parse(value)[1]).format("YYYY-MM-DD")
      );

      navigate(url.search);

      return;
    }

    url.searchParams.set(name, value);
    navigate(url.search);
  };

  const startDate = dayjs(
    searchParams.get("start_date") || dayjs().toDate()
  ).format("YYYY-MM-DD");
  const endDate = dayjs(
    searchParams.get("end_date") || dayjs().toDate()
  ).format("YYYY-MM-DD");

  const form = {
    start_date: startDate,
    end_date: endDate,
  };

  useEffect(() => {
    if (form.start_date && form.end_date) {
      dispatch(getCouponContributors(form));
    }
  }, [form.start_date, form.end_date, client_id]);

  return (
    <CouponContributions
      getCouponContributorsData={getCouponContributorsData}
      onChange={onChange}
      form={form}
    />
  );
};

export default CouponContributors;
