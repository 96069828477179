import formatNumber from "utils/strings/formatNumber";
import sumValues from "utils/strings/sumObjectValues";
import SalesTeamFilter from "./SalesTeamFilter";
import { Dispatch, Fragment, SetStateAction } from "react";

export type QuarterSalesRevenue = {
  quarter_totals: Record<string, any>;
  year_totals: Record<string, any>;
};

type Props = {
  quarterNumbers: QuarterSalesRevenue;
  setUserId: Dispatch<SetStateAction<string>>;
  isAdmin: boolean;
  loading?: boolean;
};

export default function RevenueSnapshot({
  quarterNumbers: { quarter_totals: quarterNumbers, year_totals: yearTotals },
  isAdmin,
  setUserId,
  loading,
}: Props) {
  const quarterNum = Object.entries(quarterNumbers);

  const getPaidAmount = (key: string) => {
    const matchedRow = quarterNum.find(([k]) => k === key + " Paid");
    return matchedRow?.[1] || 0;
  };
  const quarterNumbersAmounts = { ...quarterNumbers };

  for (const key in quarterNumbersAmounts) {
    if (key.includes("Paid")) {
      delete quarterNumbersAmounts[key];
    }
  }

  const slices = Array(quarterNum.length / 2)
    .fill("*")
    .map((idx, el) => ({ start: el, end: el + 4 }))
    .filter((it) => it.end % 4 === 0)
    .map((el) => ({
      ...el,
      year: Number(
        quarterNum
          .filter(([key]) => !key.includes("Paid"))
          .slice(el.start, el.end)[0][0]
          .split(" ")[1]
      ),
    }));

  console.log("slices", slices);

  return (
    <div className="bg-gray-200 w-full rounded-md">
      <div className="flex items-center justify-between pt-4  px-4">
        <h3 className="text-lg font-semibold leading-6 text-gray-900 pl-2">
          Performance Score
        </h3>

        {isAdmin && (
          <SalesTeamFilter
            loading={loading}
            enableAll
            onChange={(e) => {
              setUserId(e.target.value);
            }}
          />
        )}
      </div>
      <dl className="mt-5 p-4 grid grid-cols-1 gap-5">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow">
          <dt className="truncate text-lg font-medium text-gray-500">
            Total $ Value
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {formatNumber(sumValues(quarterNumbersAmounts), {
              prefix: "$",
              decimals: 2,
            })}
          </dd>
          <dt className="mt-8 text-base font-medium text-gray-500">
            Quarterly Revenue
          </dt>
          <dd className="mt-1">
            <div className="flex gap-12 w-full overflow-x-auto">
              {slices
                .sort((a, b) => {
                  if (a.year > b.year) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((el) => (
                  <table className="border-collapse min-w-[136px]" key={el.end}>
                    <tbody className="">
                      {quarterNum
                        .filter(([key]) => !key.includes("Paid"))
                        .slice(el.start, el.end)
                        .map(([key, value]) => {
                          return (
                            <Fragment key={key}>
                              <tr className="border-b border-gray-300 ">
                                <td className="text-sm text-gray-500 pr-4 py-2 border-r border-gray-300">
                                  {key}
                                </td>
                                <td className="text-xl font-semibold tracking-tight text-gray-900 p-2">
                                  <p>
                                    {formatNumber(value, {
                                      prefix: "$",
                                      decimals: 2,
                                    })}
                                  </p>
                                  <p>
                                    <div className="min-h-[32px]">
                                      <span className="text-green-500">
                                        {" "}
                                        {getPaidAmount(key) > 0
                                          ? "(" +
                                            formatNumber(getPaidAmount(key), {
                                              prefix: "$",
                                              decimals: 2,
                                            }) +
                                            ")"
                                          : null}
                                      </span>
                                    </div>
                                  </p>
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      <tr className="border-b border-gray-300">
                        <td className="text-sm text-gray-500 pr-4 py-2 border-r border-gray-300">
                          Totals
                        </td>
                        <td className="text-xl font-semibold tracking-tight text-gray-900 p-2">
                          <p>
                            {formatNumber(
                              yearTotals[
                                quarterNum
                                  .filter((el) => {
                                    return !el[0].includes("Paid");
                                  })
                                  .slice(el.start, el.end)[0][0]
                                  .split(" ")[1]
                              ],
                              {
                                prefix: "$",
                                decimals: 2,
                              }
                            )}
                          </p>
                          <p>
                            <div className="min-h-[32px]">
                              <span className="text-green-500">
                                (
                                {formatNumber(
                                  yearTotals[
                                    `${
                                      quarterNum
                                        .filter((el) => {
                                          return !el[0].includes("Paid");
                                        })
                                        .slice(el.start, el.end)[0][0]
                                        .split(" ")[1]
                                    } Paid`
                                  ],
                                  {
                                    prefix: "$",
                                    decimals: 2,
                                  }
                                )}
                                )
                              </span>
                            </div>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}
