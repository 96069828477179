import DateRangeSelector from "components/common/DateRange";
import Dropdown from "components/common/Dropdown";
import countries from "constants/operationCountries";
import dateFormat from "dateformat";
import { ChangeEvent, ChangeEventHandler } from "react";
import { GoGraph } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { PaginatedActionState } from "types/data";
import { Client } from "types/model/Client";
import formatNumber from "utils/strings/formatNumber";

type Props = {
  onFilterChange: ChangeEventHandler<HTMLInputElement>;
  total_items?: number;
  selectedItems?: Record<string, any>[];
  updateDate?: string;
  filterValues: Record<string, any>;
  debouncedOnFiltersChange: ChangeEventHandler<HTMLInputElement>;
  onDateSelectConfirm: () => void;
  getClientsData: PaginatedActionState<Client>;
};

const NewsletterTableHeader = ({
  onFilterChange,
  total_items,
  selectedItems,
  updateDate,
  onDateSelectConfirm,
  debouncedOnFiltersChange,
  getClientsData,
  filterValues: {
    is_home_page,
    verified,
    client,
    program_location,
    start_date,
    end_date,
    report_view,
    program_view,
  },
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="border-b dark:border-gray-700 mx-4 mt-4">
        <div className="flex flex-col-reverse md:flex-row items-center justify-between md:space-x-4 py-3">
          <div className="w-full  flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center">
            {report_view !== "1" && (
              <form
                className="w-96 md:mr-4"
                onSubmit={(e: any) => {
                  e.preventDefault();
                  onFilterChange({
                    target: {
                      name: "search",
                      value: e.target[0].value,
                    },
                  } as ChangeEvent<HTMLInputElement>);
                }}
              >
                <label
                  htmlFor="default-search"
                  className="text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="search"
                    name="search"
                    onChange={debouncedOnFiltersChange}
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Email..."
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 top-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-r-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Search
                  </button>
                </div>
              </form>
            )}
            <div className="flex items-center space-x-4">
              <div>
                <Dropdown
                  options={getClientsData.data.items.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                  loading={getClientsData.loading}
                  name="client"
                  placeholder="Select Client"
                  value={client}
                  onChange={onFilterChange}
                />
              </div>
              <div>
                <Dropdown
                  options={countries.map((el) => ({
                    label: el,
                    value: el.toLowerCase(),
                  }))}
                  name="program_location"
                  placeholder="Select Location"
                  value={program_location}
                  onChange={onFilterChange}
                />
              </div>

              <div>
                <DateRangeSelector
                  form={{ startDate: start_date, endDate: end_date }}
                  onChange={onFilterChange}
                  showClear
                  onConfirm={onDateSelectConfirm}
                />
              </div>

              <div className="flex py-1 items-center flex-1 justify-between">
                <Link
                  to={"/marketing/email?tab=2"}
                  className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Reset
                </Link>

                {report_view !== "1" && (
                  <button
                    onClick={() => {
                      const url = new URL(window.location.href);
                      url.searchParams.set("report_view", "1");
                      url.searchParams.delete("program_view");

                      navigate(url.search);
                    }}
                    className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  >
                    <GoGraph />
                  </button>
                )}

                {program_view !== "1" && (
                  <button
                    onClick={() => {
                      const url = new URL(window.location.href);
                      url.searchParams.set("program_view", "1");
                      url.searchParams.delete("report_view");
                      navigate(url.search);
                    }}
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Program View
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 pb-3 flex flex-wrap items-center justify-between my-4">
        <div className="flex flex-wrap ">
          <div className="flex items-center mt-3 mr-4">
            <input
              id="all-tasks"
              type="radio"
              value={verified}
              checked={verified === "1"}
              onChange={(e) => {
                onFilterChange({
                  target: {
                    name: "verified",
                    value: "1",
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
              name="verified"
              className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="all-tasks"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Verified
            </label>
          </div>
          <div className="flex items-center mt-3 mr-4">
            <input
              id="all-tasks"
              type="radio"
              value={verified}
              checked={verified === "0"}
              onChange={(e) => {
                onFilterChange({
                  target: {
                    name: "verified",
                    value: "0",
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
              name="verified"
              className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="all-tasks"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Pending Verification
            </label>
          </div>

          <div className="flex items-center mr-4 mt-3">
            <input
              checked={is_home_page === "1"}
              name="is_home_page"
              type="checkbox"
              onChange={(e) => {
                onFilterChange({
                  target: {
                    name: "is_home_page",
                    value: e.target.checked ? "1" : "0",
                  },
                } as ChangeEvent<HTMLInputElement>);
              }}
              className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="completed"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Homepage
            </label>
          </div>

          <div className="flex items-center mt-3 ">
            {!!selectedItems?.length && (
              <label
                className="ml-2 cursor-pointer underline text-sm font-medium text-gray-900 dark:text-gray-300"
                // onClick={handleIgnoreLinks}
              >
                Delete {selectedItems.length}
              </label>
            )}
          </div>
        </div>

        <div className="flex items-center gap-4 align-baseline">
          {(total_items || 0) > 0 &&
            report_view !== "1" &&
            program_view !== "1" && (
              <p className="font-medium text-gray-900 text-xs">
                {formatNumber(Number(total_items), { decimals: 0 })} Subscribers
              </p>
            )}
          {updateDate && report_view !== "1" && program_view !== "1" && (
            <div className="flex">
              <svg
                aria-hidden="true"
                className="w-4 h-4 mr-1 -ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>

              <p className="font-medium text-gray-900 text-xs">
                Last Subscription:{" "}
                {dateFormat(updateDate, "dd/mm/yyyy h:MM:ss TT")}{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewsletterTableHeader;
