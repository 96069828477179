import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import Thunk from "../../Thunk";
import { defaultS3Config } from "helpers/s3";
import S3 from "react-aws-s3-typescript";

export default Thunk("api-clients/createSeasonalLink", async (data = {}) => {
  if (data.file) {
    const s3 = new S3({
      ...defaultS3Config,
      dirName: `img/${data.client}/placements/${data.id}`,
    });

    const result = await s3.uploadFile(data.file);
    const { location } = result;
    data["image_url"] = location;
    data["position"] = data.id;
  }

  return instance.post(`${DISCOUNT_CODES_API}/campaigns/seasonal-links/`, data);
});
