import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import ProgramComponent, { UpdateStoreOptions } from "layout/Program";
import { ChangeEventHandler, useEffect, useState } from "react";
import createUpdateStore from "redux/thunks/app/api-clients/createUpdateStore";
import { toast } from "react-hot-toast";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import getActivePrograms from "redux/thunks/app/api-clients/getActivePrograms";
import { resetProgram } from "redux/slices/clients/getProgram";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import getStoreTypes from "redux/thunks/app/api-clients/getStoreTypes";
import getCouponPolicies from "redux/thunks/app/api-clients/getCouponPolicies";
import { Option } from "types/option";

const Program = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form, setForm] = useState<Record<string, any>>({
    coupon_policies: [],
  });

  const dispatch = useAppDispatch();

  const {
    getProgram: programDetails,
    createStore: createUpdateStoreData,
    getCategories: getCategoriesData,
    getActivePrograms: getActiveProgramsData,
    getStoreTypes: getStoreTypesData,
    getCouponPolicies: getCouponPoliciesData,
  } = useAppSelector(
    ({
      getProgram,
      createStore,
      getCategories,
      getActivePrograms,
      getStoreTypes,
      getCouponPolicies,
    }) => ({
      getProgram,
      createStore,
      getCategories,
      getActivePrograms,
      getStoreTypes,
      getCouponPolicies,
    })
  );

  const mid = searchParams.get("mid");
  const slug = searchParams.get("slug");
  const client_id = searchParams.get("client_id");

  useEffect(() => {
    if (slug) {
      dispatch(getProgram({ query: slug, client_id: client_id, useDis: "1" }));
    }
  }, [slug]);

  useEffect(() => {
    dispatch(getCouponPolicies({})).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setForm((f) => ({
          ...f,
          coupon_policies: res.payload.map((it: Record<string, any>) => ({
            selected: false,
            label: it.name,
            value: it.id,
            id: it.id,
          })),
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (mid?.length && !slug) {
      dispatch(
        getProgram({
          query: mid,
        })
      );
    }
  }, [mid, slug]);

  useEffect(() => {
    dispatch(getStoreTypes({}));
    dispatch(getCategories({}));
    dispatch(getActivePrograms({ page: 1 }));
  }, []);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const name = e.target.name;
    if (name === "short_form" || name === "long_form") {
      setForm((f) => ({
        ...f,
        content: {
          ...f.content,
          [name]: e.target.value,
        },
      }));
    } else {
      setForm((f) => ({ ...f, [name]: e.target.value }));
    }
  };

  const onFormSubmit = () => {
    const { followers, ...rest } = form;

    const faqs: Record<string, any>[] = [];

    Object.entries(form.faqsForm || {}).forEach((_, idx) => {
      const item = {
        question: form.faqsForm[`Question ${idx + 1}`],
        answer: form.faqsForm[`Answer ${idx + 1}`],
      };

      faqs.push(item);
    });

    dispatch(
      createUpdateStore({
        data: {
          ...rest,
          faqs: faqs.filter((el) => el.question && el.answer),
          coupon_policies: form.coupon_policies
            .filter((el: Option) => el.selected)
            .map((el: Record<string, any>) => el.id),
          primary_category: form.primary_category?.id || form.primary_category,
          slug: programDetails.data.slug,
          new_slug: form.slug,
          id: programDetails.data.id,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        const errors: Array<Array<string>> = Object.values(
          res.payload.error || res.payload
        );

        toast.error(
          "An error occurred \n" + errors?.[0]?.[0] ||
            res.payload.message ||
            res.payload.detail ||
            ""
        );
      } else {
        toast.success("Changes saved");
      }
    });
  };

  const handleUpdateStore = (options: UpdateStoreOptions) => {
    if (options.toggleIsActive) {
      dispatch(
        createUpdateStore({
          data: {
            id: programDetails.data.id,
            slug: programDetails.data.slug,
            new_slug: form.slug,
            is_active: !programDetails.data.is_active,
          },
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Changes saved");
        }
      });
    }
  };

  useEffect(() => {
    if (programDetails.data.id) {
      setForm((f) => ({
        ...programDetails.data,
        coupon_policies: form.coupon_policies.map((el: Record<string, any>) =>
          programDetails.data.coupon_policies
            .map((it: Record<string, any>) => it.id)
            .includes(el.id)
            ? { ...el, selected: true }
            : el
        ),
      }));
    }
  }, [programDetails.data.id]);

  useEffect(() => {
    return () => {
      dispatch(resetProgram());
    };
  }, []);

  const onTabChange = (index: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("tab", String(index));
    navigate(url.search);
  };

  return (
    <ProgramComponent
      onTabChange={onTabChange}
      handleUpdateStore={handleUpdateStore}
      getCouponPolicyData={getCouponPoliciesData}
      onFormSubmit={onFormSubmit}
      form={{ ...form, tab: searchParams.get("tab") || 0 }}
      onChange={onChange}
      programDetails={programDetails}
      createUpdateStore={{
        ...createUpdateStoreData,
        loading: createUpdateStoreData.loading,
      }}
      getCategoriesData={getCategoriesData}
      getActivePrograms={getActiveProgramsData}
      getStoreTypesData={getStoreTypesData}
      setForm={setForm}
    />
  );
};

export default Program;
