import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { CLIENT_ID_DISCOUNT_CODES_COM } from "constants/clientWebsitesIds";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

export default createAsyncThunk(
  "clients/campaigns/getPaidPlacements",
  async (params: Record<string, any>, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }

      // if (String(params.location) != CLIENT_ID_DISCOUNT_CODES_COM) {
      //   delete params.location;
      // }

      const clientWebsite = new ClientAPI();

      const response = await clientWebsite.getPaidPlacements(params);

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
