import { Column } from "types/column";

const LINK_COLUMNS = [
  {
    key: "checkbox",
    label: "",
    component: "components/common/Checkbox",
  },
  {
    key: "created_at",
    label: "Created",
    isSortable: true,
  },
  {
    key: "network_advertiser.name",
    label: "Program Name",
    isSortable: false,
  },
  {
    key: "network",
    label: "Network",
    isSortable: false,
  },

  { key: "name", label: "Coupon Headline", isSortable: false },

  { key: "description", label: "Coupon Description", isSortable: false },

  { key: "coupon_code", label: "Code", isSortable: true },
  { key: "end_date", label: "Expiration Date", isSortable: true },

  { key: "Actions", label: "Action", isSortable: false },
] as Column[];

export default LINK_COLUMNS;
