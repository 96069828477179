import DateRangeSelector from "components/common/DateRange";
import Message from "components/common/Message";
import Spinner from "components/common/layout/Spinner";
import ContributorResult from "layout/contributions/ContributorResult";
import dayjs from "dayjs";
import { ChangeEventHandler } from "react";
import { CouponContributionsActionState } from "redux/slices/clients/performance/getCouponContributors";
import Layout from "components/layout/Layout";

type Props = {
  getCouponContributorsData: CouponContributionsActionState;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form: Record<string, any>;
};

const CouponContributions = ({
  onChange,
  getCouponContributorsData,
  form,
}: Props) => {
  return (
    <Layout>
      <div className="flex gap-4 my-4 mx-4">
        <DateRangeSelector
          disableConfirm
          form={{
            startDate: dayjs(form.start_date || dayjs().toDate()),
            endDate: dayjs(form.end_date || dayjs().toDate()),
          }}
          onChange={onChange}
        />
      </div>

      <div className="p-4">
        {getCouponContributorsData.error && (
          <Message
            error
            description={JSON.stringify(
              getCouponContributorsData.error
            ).substring(0, 100)}
          />
        )}
        {getCouponContributorsData.loading && <Spinner />}

        {getCouponContributorsData.data && (
          <ContributorResult counts={getCouponContributorsData.data} />
        )}
      </div>
    </Layout>
  );
};

export default CouponContributions;
