import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  query: string;
  isCRM?: string;
  useDis?: string;
};

export default createAsyncThunk(
  "clients/search-active-programmes",
  async ({ query, useDis, isCRM }: Params, ThunkAPI) => {
    const selectedClientId = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);

    console.log("query", query);

    if (isCRM === "1") {
      try {
        const response = await instance.get(
          `${DISCOUNT_CODES_API}/stores/search-stores/?search_text=${encodeURIComponent(
            query
          )}&isCRM=1`
        );
        return response.data;
      } catch (error: any) {
        return ThunkAPI.rejectWithValue(
          error.response?.data || {
            message: "Could not fetch",
          }
        );
      }
    }

    if (useDis === "1") {
      try {
        const response = await instance.get(
          `${DISCOUNT_CODES_API}/stores/search-stores/?search_text=${encodeURIComponent(
            query
          )}&client=${selectedClientId}`
        );
        return response.data;
      } catch (error: any) {
        return ThunkAPI.rejectWithValue(
          error.response?.data || {
            message: "Could not fetch",
          }
        );
      }
    }

    try {
      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }
      const clientWebsite = new ClientAPI(selectedClientId);

      const response = await clientWebsite.searchPrograms(query);

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
