import { capitalize } from "lodash";
import toPascalCase from "utils/strings/toPascalCase";

type Props = {
  title: string;
  selector: string;
  rows: Record<string, any>;
};

const CardResultSection = ({ title, selector = "network", rows }: Props) => {
  const selected = Object.keys(rows)
    .filter((key) => key.startsWith(selector) && key.endsWith("count"))
    .map((el) => ({
      [el.replace(`${selector}_`, "").replace("_count", "")]: rows[el],
    }));

  const filteredData = selected.filter((item) => {
    const value = Object.values(item)[0];
    return value > 0;
  });

  return (
    <div className="px-4 py-2">
      <h1>{title}</h1>

      {filteredData.map((item, _) => {
        const key = Object.keys(item)[0];
        const v = Object.values(item)[0];

        return (
          <dl
            className="divide-y divide-gray-100 px-2 text-sm leading-6"
            key={key}
          >
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">{toPascalCase(capitalize(key))}</dt>
              <span>{String(v)}</span>
            </div>
          </dl>
        );
      })}
    </div>
  );
};

export default CardResultSection;
