import ProgramHeaderComponent from "components/program/ProgramHeader";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { UpdateStoreOptions } from "layout/Program";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetQueryCampaignsResults } from "redux/slices/marketing/sem/searchAdCampaigns";
import searchAdCampaigns from "redux/thunks/marketing/sem/searchAdCampaigns";
import getProgramAPI from "redux/thunks/app/api-clients/getProgram";
import { navigateToMerchantPage } from "utils/urls/addHttp";

type Props = {
  handleUpdateStore?: (options: UpdateStoreOptions) => void;
  wrapperClassName?: string;
};

const ProgramHeader = ({ handleUpdateStore, wrapperClassName }: Props) => {
  const [params] = useSearchParams();
  const slug = params.get("slug");
  const client_id = params.get("client_id");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    getProgram,
    getClients: { data: clientsList },
    searchAdCampaigns: searchAdCampaignsData,
  } = useAppSelector(({ getProgram, getClients, searchAdCampaigns }) => ({
    getProgram,
    getClients,
    searchAdCampaigns,
  }));

  const handleViewPage = () => {
    const client = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID) || null;
    navigateToMerchantPage(clientsList.items, getProgram.data.slug, client);
  };

  const onBackClicked = () => {
    if (params.get("tab") == "create-coupon") {
      navigate("/oma");
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (getProgram.data.slug) {
      dispatch(resetQueryCampaignsResults());
      dispatch(
        searchAdCampaigns({
          search_text: getProgram.data.slug,
          add_meta_data: true,
        })
      );
    }
  }, [getProgram.data.slug]);

  useEffect(() => {
    if (slug) {
      dispatch(
        getProgramAPI({
          query: slug || "",
          client_id: client_id || null,
          useDis: "1",
        })
      );
    }
  }, [slug]);

  return (
    <ProgramHeaderComponent
      handleUpdateStore={handleUpdateStore}
      programDetails={getProgram}
      handleViewPage={handleViewPage}
      onBackClicked={onBackClicked}
      wrapperClassName={wrapperClassName}
      searchAdCampaignsData={searchAdCampaignsData}
    />
  );
};

export default ProgramHeader;
