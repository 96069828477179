export enum WEBSITE_CLIENT_NAMES {
  DESCONTO_COM_BR = "desconto.com.br",
  COUPONCODES_NL = "couponcodes.nl",
  DISCOUNTCODE_COM = "discountcode.com",
  CODEPROMO_FR = "codepromo.fr",
  CODES_DE = "codes.de",
  COUPONS_CA = "coupons.ca",
  CODES_CO_UK = "codes.co.uk",
  PROMO_CODES_COM = "promo-codes.com",
  SLEVOVYKOD_CZ = "slevovykod.cz",
  DISCOUNT_CODES_COM = "discount-codes.com",
  CODES_CH = "codes.ch",
}

type WebsitesNameIdMap = {
  [key in WEBSITE_CLIENT_NAMES]: string;
};

export const websitesIdNameMap: { [key: string]: WEBSITE_CLIENT_NAMES } = {
  "1": WEBSITE_CLIENT_NAMES.DESCONTO_COM_BR,
  "2": WEBSITE_CLIENT_NAMES.COUPONCODES_NL,
  "3": WEBSITE_CLIENT_NAMES.DISCOUNTCODE_COM,
  "4": WEBSITE_CLIENT_NAMES.CODEPROMO_FR,
  "5": WEBSITE_CLIENT_NAMES.CODES_DE,
  "6": WEBSITE_CLIENT_NAMES.COUPONS_CA,
  "7": WEBSITE_CLIENT_NAMES.CODES_CO_UK,
  "8": WEBSITE_CLIENT_NAMES.PROMO_CODES_COM,
  "9": WEBSITE_CLIENT_NAMES.SLEVOVYKOD_CZ,
  "10": WEBSITE_CLIENT_NAMES.DISCOUNT_CODES_COM,
  "11": WEBSITE_CLIENT_NAMES.CODES_CH,
};

export const websitesNameIdMap: WebsitesNameIdMap = Object.keys(
  websitesIdNameMap
).reduce((acc, key) => {
  const websiteId = websitesIdNameMap[key];
  acc[websiteId] = key;
  return acc;
}, {} as WebsitesNameIdMap);

export const CLIENT_ID_DESCONTO_COM_BR = "1";
export const CLIENT_ID_COUPONCODES_NL = "2";
export const CLIENT_ID_DISCOUNTCODE_COM = "3";
export const CLIENT_ID_CODEPROMO_FR = "4";
export const CLIENT_ID_CODES_DE = "5";
export const CLIENT_ID_COUPONS_CA = "6";
export const CLIENT_ID_CODES_CO_UK = "7";
export const CLIENT_ID_PROMO_CODES_COM = "8";
export const CLIENT_ID_SLEVOVYKOD_CZ = "9";
export const CLIENT_ID_DISCOUNT_CODES_COM = "10";
export const CLIENT_ID_CODES_CH = "11";
