import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
  useEffect,
  useCallback,
  ChangeEventHandler,
  useState,
  FormEventHandler,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getLinks from "redux/thunks/links/getLinks";
import OMAHomeComponent from "layout/oma/OMAHome";
import { Link } from "types/model/Link";
import toast from "react-hot-toast";
import IgnoreLink from "redux/thunks/links/IgnoreLink";
import { Option } from "types/option";
import getLinksByProgram from "redux/thunks/links/getLinksByProgram";
import getAffiliateNetworks from "redux/thunks/networks/getAffiliateNetworks";

const OMAHome = () => {
  const [searchStr, setSearchStr] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedItems, setSelectedItems] = useState<Record<string, any>[]>([]);
  const [networkOptions, setNetworkOptions] = useState<Option[]>([]);
  const [expandedItemId, setExpandedItem] = useState<number | string>(0);

  const isProgramView = searchParams.has("is_program_view");

  const {
    page,
    network_id,
    rank,
    search_text,
    store_location,
    has_coupon_code,
    program_has_no_codes,
    program_has_no_coupons,
    has_end_date = "1",
    program_has_search_campaign,
    ordering,
  } = Object.fromEntries([...searchParams]);

  const {
    getAllLinks: getAllLinksState,
    ignoreLinks: { loading: ignoreLoading },
    affiliateNetworks,
    getAllLinksByProgram: getAllLinksByProgramData,
    createUpdateCoupon: { data: createUpdateCouponData },
  } = useAppSelector(
    ({
      getAllLinks,
      ignoreLinks,
      affiliateNetworks,
      getAllLinksByProgram,
      createUpdateCoupon,
    }) => ({
      getAllLinks,
      ignoreLinks,
      affiliateNetworks,
      createUpdateCoupon,
      getAllLinksByProgram,
    })
  );

  const getData = useCallback(
    (page = 1) => {
      dispatch(getLinks({ page }));
    },
    [dispatch]
  );

  const onActivatePressed = (link: Record<string, any>) => {
    navigate(
      `/oma/program?&mid=${link?.network_advertiser?.external_id}&program_id=${link?.network_advertiser?.id}&network=${link?.network_advertiser?.network}&link=${link.id}&tab=create-coupon`,

      {
        state: link,
      }
    );
  };

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const onFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.name === "search_text") {
      setSearchStr(e.target.value);
      return;
    }
    if (e.target.checked) {
      const url = new URL(window.location.href);
      url.searchParams.set("page", "1");
      url.searchParams.set(e.target.name, "1");
      navigate(url.search);
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set("page", "1");
      url.searchParams.set(e.target.name, "0");
      navigate(url.search);
    }
  };

  const onSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    url.searchParams.set("page", "1");
    url.searchParams.set("search_text", searchStr);
    navigate(url.search);
  };

  const onItemSelectChange = (link: Link) => {
    const isChecked = selectedItems.map((el) => el.id).includes(link.id);

    if (isChecked) {
      setSelectedItems((all) => all.filter((it) => it.id !== link.id));
    } else {
      setSelectedItems((all) => [link, ...all]);
    }
  };

  const toggleIsProgramView = () => {
    const url = new URL(window.location.href);

    if (url.searchParams.has("is_program_view")) {
      navigate("/oma");
    } else {
      url.searchParams.set("is_program_view", "1");
      navigate(url.search);
    }
  };

  useEffect(() => {
    if (affiliateNetworks.data.items.length) {
      const allAffiliateNetworks = affiliateNetworks.data.items.map((item) => ({
        label: item.name,
        value: item.network_id,
        selected: network_id === item.network_id,
      }));
      setNetworkOptions(allAffiliateNetworks);
    } else {
      dispatch(getAffiliateNetworks({ page: 1 }));
    }
  }, [affiliateNetworks.data.items.length]);

  useEffect(() => {
    if (!isProgramView) {
      dispatch(
        getLinks({
          page: Number(page) || 1,
          search_text,
          has_coupon_code,
          network_id,
          store_location,
          ordering,
          rank,
          program_has_no_codes,
          program_has_no_coupons,
          program_has_search_campaign,
          has_end_date: has_end_date || "1",
        })
      );
    }
  }, [getData, isProgramView, searchParams]);

  useEffect(() => {
    if (isProgramView) {
      dispatch(
        getLinksByProgram({
          search_text,
          has_coupon_code,
          network_id,
          store_location,
          rank,
          program_has_no_coupons,
          program_has_no_codes,
          page: Number(page) || 1,
          program_has_search_campaign,
        })
      );
    }
  }, [isProgramView, searchParams]);

  const handleIgnoreLinks = (link?: Record<string, any>) => {
    if (link?.id) {
      setSelectedItems([link]);
    }
    dispatch(
      IgnoreLink({
        data: {
          ids: link?.id ? [link.id] : selectedItems.map((it) => String(it.id)),
          action: "listing",
        },
      })
    )
      .then((res) => {
        setSelectedItems([]);
        if (res.meta.requestStatus === "fulfilled") {
          // dispatch(
          //   getLinks({
          //     page: Number(page) || 1,
          //     search_text,
          //     has_coupon_code,
          //     network_id,
          //     store_location,
          //     rank,
          //     skipCache: true,
          //   })
          // );
          toast.success("Link(s) ignored");
        }
      })
      .catch((err) => {
        //
      });
  };

  const onSelectedNetworkChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const isSelected = e.currentTarget.checked;
    setNetworkOptions((opts) =>
      opts.map((v) =>
        v.value === e.currentTarget?.name ? { ...v, selected: isSelected } : v
      )
    );

    const url = new URL(window.location.href);
    url.searchParams.set("network_id", e.target.value);

    navigate(url.search);
  };

  const onSelectedRankChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNetworkOptions((opts) =>
      opts.map((v) => (v.value === e.currentTarget?.name ? { ...v } : v))
    );

    const url = new URL(window.location.href);
    url.searchParams.set(e.target.name, e.target.value);

    navigate(url.search);
  };

  useEffect(() => {
    if (createUpdateCouponData.link_id) {
      dispatch(
        getLinks({
          page: Number(page) || 1,
          search_text,
          has_coupon_code,
          network_id,
          store_location,
          rank,
          skipCache: true,
        })
      );
    }
  }, [createUpdateCouponData]);

  const handleExpandItem = (item: Record<string, any>) => {
    if (expandedItemId === item.id) {
      setExpandedItem(0);
    } else {
      setExpandedItem(item.id);
    }
  };

  return (
    <OMAHomeComponent
      onPageChange={getNewPage}
      program_has_no_codes={program_has_no_codes}
      program_has_no_coupons={program_has_no_coupons}
      program_has_search_campaign={program_has_search_campaign}
      network={network_id}
      has_end_date={has_end_date}
      currentPage={Number(page) || 1}
      onActivatePressed={onActivatePressed}
      getAllLinksState={getAllLinksState}
      onFilterChange={onFilterChange}
      has_coupon_code={has_coupon_code}
      onSearchSubmit={onSearchSubmit}
      onItemSelectChange={onItemSelectChange}
      selectedItems={selectedItems}
      handleIgnoreLinks={handleIgnoreLinks}
      ignoreLoading={ignoreLoading}
      networkOptions={networkOptions}
      onSelectedNetworkChange={onSelectedNetworkChange}
      onSelectedRankChange={onSelectedRankChange}
      toggleIsProgramView={toggleIsProgramView}
      isProgramView={isProgramView}
      getAllLinksByProgramData={getAllLinksByProgramData}
      store_location={store_location}
      rank={rank}
      expandedItemId={expandedItemId}
      handleExpandItem={handleExpandItem}
    />
  );
};

export default OMAHome;
