import CouponContributions from "containers/oma/contributions/CouponContributors";

const routes = [
  {
    path: "/contributions/coupons",
    element: <CouponContributions />,
  },
];

export default routes;
