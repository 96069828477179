import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import createSeasonalLink from "redux/thunks/app/placements/campaigns/createSeasonalLink";
import { ActionState } from "types/data";

const initialState: ActionState = {
  data: {},
  loading: false,
  error: null,
};

const createSeasonalLinkSlice = createSlice({
  name: "createSeasonalLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSeasonalLink.pending, (state) => ({
        ...state,
        error: null,
        loading: true,
      }))
      .addCase(
        createSeasonalLink.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: payload,
            loading: false,
          };
        }
      )
      .addCase(
        createSeasonalLink.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        })
      );
  },
});

export default createSeasonalLinkSlice.reducer;
