import Button from "components/common/Button";
import DraggableRow from "components/common/DraggableRow";
import { Dispatch, SetStateAction } from "react";
import { RowRenderer } from "components/common/layout/DataTable";
import { SeasonalLink } from "types/model/SeasonalLink";
import SeasonalLinkRow from "components/campaigns/SeasonalLinkRow";
import Layout from "components/layout/Layout";
import { ActionState } from "types/data";
import CategorySelector from "components/common/CategorySelector";

type Props = {
  loading: boolean;
  data: Array<SeasonalLink>;
  saveSortOrder: () => void;
  updateSeasonalLink: (item: SeasonalLink) => void;
  moveDraggableRow: (dragIndex: number, hoverIndex: number) => void;
  setSortedItems: Dispatch<SetStateAction<SeasonalLink[]>>;
  updateSeasonalLinksData: ActionState;
  addNewCategory: (item: Record<string, any>) => void;
  deleteSeasonalLink: (item: Record<string, any>) => void;
  deleteLoading: boolean;
};

export default function SeasonalLinksLayout({
  data,
  moveDraggableRow,
  saveSortOrder,
  updateSeasonalLink,
  loading,
  updateSeasonalLinksData,
  addNewCategory,
  deleteSeasonalLink,
  deleteLoading,
}: Props) {
  const renderRow: RowRenderer<SeasonalLink> = (row) => (
    <SeasonalLinkRow
      updateSeasonalLink={updateSeasonalLink}
      updateSeasonalLinksData={updateSeasonalLinksData}
      row={row}
      key={row.id}
      deleteLoading={deleteLoading}
      deleteSeasonalLink={() => deleteSeasonalLink(row)}
    />
  );

  return (
    <Layout loading={loading}>
      <h2 className="text-lg my-5"> Global Header Seasonal Links</h2>
      <div className="w-full flex items-center justify-between">
        <CategorySelector
          label="Select Category"
          onResultClick={(item: Record<string, any>) => {
            addNewCategory(item);
          }}
        />
        <Button
          text="Save changes"
          loading={updateSeasonalLinksData.loading}
          className="bg-indigo-600 text-white "
          onClick={saveSortOrder}
        />
      </div>
      <div className="mt-4 flow-root">
        <div className=" -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6"
                    >
                      Category Name
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6"
                    >
                      Run Dates
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6"
                    ></th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((item: Record<string, any>, idx: number) => (
                    <DraggableRow
                      key={item.id}
                      index={idx}
                      id={item.id}
                      row={item}
                      rowClassName="border-1   border-gray-300"
                      renderRow={renderRow as RowRenderer<Record<string, any>>}
                      moveDraggableRow={moveDraggableRow}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
