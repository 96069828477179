import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

export default createAsyncThunk(
  "api-clients/checkPaidPlacementsAvailability",
  async (data: Record<string, any>, ThunkAPI) => {
    try {
      const selectedClientId = localStorage.getItem(
        LOCAL_KEY_SELECTED_CLIENT_ID
      );

      if (!selectedClientId) {
        return ThunkAPI.rejectWithValue({
          message: "No clients available",
        });
      }

      const clientWebsite = new ClientAPI();

      const payload = {
        ...data,
        placement_type: data.placement_type?.id || data.placement_type,
        category: data.category?.slug || data.category,
      };

      const response = await clientWebsite.checkPlacementPosAvailability(
        payload
      );

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          }
      );
    }
  }
);
