import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getCategories from "redux/thunks/app/api-clients/categories/getCategories";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import TopCategoriesCampaigns from "layout/oma/campaigns/TopCategoriesCampaigns";
import getTopCategories from "redux/thunks/app/placements/campaigns/getTopCategories";
import {
  removeCategory,
  setTopCategories,
} from "redux/slices/clients/placements/campaigns/getTopCategories";
import saveTopCategories from "redux/thunks/app/placements/campaigns/saveTopCategories";
import { toast } from "react-hot-toast";
import update from "immutability-helper";
import { TopProgramCategory } from "types/model/Category";
import { useSearchParams } from "react-router-dom";

type Props = {
  globalLayout?: boolean;
};

const TopCategories = ({ globalLayout }: Props) => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const {
    getClients: { data: clientsList },
    getTopCategories: getTopCategoriesData,
    saveTopCategories: saveTopCategoriesData,
  } = useAppSelector(
    ({ getClients, getCategories, getTopCategories, saveTopCategories }) => ({
      getClients,
      getCategories,
      getTopCategories,
      saveTopCategories,
    })
  );

  const [sortedItems, setSortedItems] = useState<TopProgramCategory[]>([]);

  const { loading } = useAppSelector((state) => state.getCategories);

  useEffect(() => {
    dispatch(getCategories({}));
    dispatch(getTopCategories());
  }, [params]);

  const navigateToClientCategory = (slug: string) => {
    const client = clientsList.items.find(
      (it) =>
        String(it.id) === localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID)
    );
    if (client?.website_url) {
      window?.open(`${client.website_url}/explore/${slug}`, "_blank")?.focus();
    }
  };

  const handlePersistUpdates = () => {
    dispatch(
      saveTopCategories({
        data: sortedItems.map((el, idx) => ({
          position: idx + 1,
          id: el.id,
        })),
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Changes saved");
      }
    });
  };

  const handleRemoveCategory = (result: TopProgramCategory) => {
    dispatch(removeCategory(result));
  };

  useEffect(() => {
    setSortedItems(getTopCategoriesData.data);
  }, [getTopCategoriesData.data.length]);

  useEffect(() => {
    if (saveTopCategoriesData.data.saved) {
      setSortedItems(getTopCategoriesData.data);
    }
  }, [saveTopCategoriesData.data.saved]);

  useEffect(() => {
    if (sortedItems) {
      dispatch(setTopCategories(sortedItems));
    }
  }, [sortedItems]);

  const moveDraggableRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSortedItems((order) =>
        update(order, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, order[dragIndex]],
          ],
        })
      );
    },
    []
  );

  return (
    <TopCategoriesCampaigns
      loading={loading}
      navigateToClientCategory={navigateToClientCategory}
      data={sortedItems}
      moveDraggableRow={moveDraggableRow}
      setSortedItems={setSortedItems}
      saving={saveTopCategoriesData.loading}
      handlePersistUpdates={handlePersistUpdates}
      handleRemoveCategory={handleRemoveCategory}
      globalLayout={globalLayout}
    />
  );
};

export default TopCategories;
